<template>
<v-row>
  <v-col cols="12" md="6">
    <search-box
        label="Item Pai"
        popup-label="Selecione um Item"
        :idPai.sync="value.idPai"
        :tituloPai.sync="value.tituloPai"
        :hidden-clear="true"
        :value=" itensPais"
        :loader-fn="loaderItensPais"
        item-key="idPai"
        item-text="tituloPai"
        >
      <template v-slot:empty>
        <p class="ma-2">
          Nenhum Item Pai encontrado
        </p>
      </template>
    </search-box>
  </v-col>
  <v-col cols="12" md="6">
    <v-text-field label="* Título"
                  dense
                  :rules="[
                      this.$validators.string.required,
                        v => this.$validators.string.greaterThanOrEquals(v, 1),
                        v => this.$validators.string.lessThanOrEquals(v, 200)
                      ]"
                  v-model.trim="value.titulo"/>
  </v-col>
  <v-col cols="12" sm="6">
    <lookup-dominio label="* Aparece no Menu?"
                    :hidden-clear="true"
                    hint="Informe se é um item de menu"
                    v-model="value.flgVisivel"
                    :type="lookups.flgVisivel"
                    :rules="[
                      this.$validators.notNullOrUndefined
                    ]"/>
  </v-col>    
  <v-col cols="12" md="6">
    <v-text-field label="* EndPoint"
                  dense
                  :disabled="value.flgVisivel==='N'"
                  v-model.trim="value.url"/>
  </v-col>
  <v-col cols="12" md="8">
    <v-text-field label="* Recurso"
                  dense
                  :rules="[
                      this.$validators.string.required,
                        v => this.$validators.string.greaterThanOrEquals(v, 1),
                        v => this.$validators.string.lessThanOrEquals(v, 200)
                      ]"
                  v-model.trim="value.link"/>
  </v-col>
  <v-col cols="12" md="4">
    <v-text-field label="Ícone"
                  dense
                  :rules="[
                        v => this.$validators.string.greaterThanOrEquals(v, 1),
                        v => this.$validators.string.lessThanOrEquals(v, 200)
                      ]"
                  v-model.trim="value.icon"/>
  </v-col>  
  <v-col cols="12"> 
    <search-box-multiple
        label="* NívelAcesso"
        class="search-box-cursos"
        popup-label="Selecione os Níveis de Acesso"
        v-model="value.niveisAcesso"
        :loader-fn="loaderNivelAcesso"
        item-key="key"
        item-text="valor"
        :rules="[
              this.$validators.notNullOrUndefined,
          ]">
      <template v-slot:selection="{item}" >
        <div @click.stop  class="curso-chip v-chip">
          <div class="item-description">{{item.valor}}</div>
          <div class="remove-button" @click="removeNivel(item)">
            <v-btn x-small icon class="ma-0 pa-0">
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </div>
        </div>
      </template>
      <template v-slot:empty>
        <p class="ma-2">
          Nenhum Nível de Acesso encontrada
        </p>
      </template>
    </search-box-multiple>
  </v-col>  
</v-row>
</template>

<script>
import {ItemDeDominio, findFormItemDominio} from "../../../api/itemsDominio";
import LookupDominio from "../common/LookupDominio";
import SearchBox from "../common/SearchBox";
import {findSomentePai as findAllItensPais} from "../../../api/gestaoAcesso";
import SearchBoxMultiple from "../common/SearchBoxMultiple";

export default {
  name: "GestaoAcessoForm",
  components: {SearchBox, LookupDominio, SearchBoxMultiple},
  props: {
    value: {
      type: Object
    }
  },
  data () {
    return {
      lookups: {
        flgVisivel: ItemDeDominio.FLG_PADRAO,
      },      
      checkBoxFlagValor: false,
    }
  },
  computed: {
     itensPais() {
      if (this.value.idPai == null) return null;
      return {
        idPai: this.value.idPai,
        tituloPai: this.value.tituloPai
      }
    },
  },
  methods: {
    removeNivel(item) {
      this.value.niveisAcesso = this.value.niveisAcesso.filter(v => v.key !== item.key);
    },    
    loaderItensPais(page, search) {
      return findAllItensPais(page, {
        titulo: search
      });
    },
    loaderNivelAcesso(page, search){
      const niveis = findFormItemDominio(1,ItemDeDominio.NIVEL_ACESSO_USUARIO);
      return niveis;
    }
  },
}
</script>

<style scoped lang="scss">
.curso-chip {
  padding-right: 0;
  cursor: initial;
  background-color: rgba(0,0,0,0.1);
  height: 32px;
  border-radius: 16px;
  border: 1px solid #dedede;
  display: flex;
  justify-content: center;
  align-items: center;

  .item-description {
    min-width: 150px;
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 5px;
  }
  .remove-button {
    height: 32px;
    cursor: pointer;
    background-color: rgba(0,0,0,0.2);
    border-left: 2px solid #dedede;
    padding: 5px 8px !important;
    transition: background-color 0.5s ease 0s;
    &:hover {
      background-color: rgba(0,0,0,0.3);
    }

  }

}
</style>